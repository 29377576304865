import React from 'react';
import { CardText } from 'material-ui/Card';

const styles = {
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        placeContent: 'center space-around',
        flexDirection: 'column',
        height: '100%',
        width: window.innerWidth - 32,
        backgroundColor: '#fafafa',
        zIndex: 9999,
        padding: '0 16px',
        textAlign: 'center'
    },
    icon: {
        height: 35,
        width: 35,
        margin: '5px 15px'
    },
    iconWrapper: {
        height: 42,
        width: 42,
        display: 'inline'
    },
    image: {
        width: '70%'
    }
};

export default (props) => {
    return (
        <CardText style={styles.root}>
            <div>
                <h1>Tô Subindo!</h1>
                <br />
                <h2>
                    Use as escadas, ganhe pontos e troque por vantagens, como discontos na VitaSport
                </h2>
                <h2>
                    Esta é uma versão beta e experimental
                </h2>
            </div>
            <div>
                <a href='https://to-subindo.firebaseapp.com' style={styles.iconWrapper}>
                    <img alt='chrome-button' style={{ ...styles.image, marginBottom: 10 }} src={'https://goodburger.appmasters.io/assets/images/browser-badge.png'} />
                </a>
                <br />
                <a href='https://play.google.com/store/apps/details?id=io.appmasters.tosubindo' style={styles.iconWrapper}>
                    <img alt='android-button' style={styles.image} src={'https://goodburger.appmasters.io/assets/images/google-play-badge.png'} />
                </a>
            </div>
        </CardText>
    );
};
