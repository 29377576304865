import {
    orange800,
    orange500,
    orange200,
    white,
    fullBlack
} from 'material-ui/styles/colors';

const Colors = {
    primary: {
        default: orange800,
        light: orange200,
        text: fullBlack
    },
    secondary: {
        default: orange500,
        text: white
    }
};

export { Colors };
