import update from 'immutability-helper';
import {
    STAIRS_DELETE_OBJECT,
    STAIRS_GET_OBJECT,
    STAIRS_UPDATE_OBJECT,
    STAIRS_NEW_OBJECT,
    STAIRS_GET_OBJECTS,
    STAIRS_INPUT_CHANGED,
    STAIRS_CREATE_OBJECT,
    STAIRS_LOADING,
    STAIRS_LOADING_ONLINE,
    STAIRS_ERROR,
    STAIRS_GET_RANKING
} from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {
    items: [],
    item: null,
    input: {
        title: '',
        location: '',
        floors: '',
        user: '',
        _virtual: {}
    },
    ranking: [],
    body: '',
    loading: false,
    loadingOnline: false,
    error: false
};

export default (state = _.cloneDeep(INITIAL_STATE), action) => {
    // console.log(action.type)
    switch (action.type) {
    case STAIRS_LOADING:
        return {
            ...state,
            loading: action.payload
        };
    case STAIRS_LOADING_ONLINE:
        return {
            ...state,
            loading: action.payload
        };
    case STAIRS_ERROR:
        return {
            ...state,
            error: action.payload
        };
    case STAIRS_INPUT_CHANGED:
    {
        let obj = {};
        obj[action.payload.key] = action.payload.value;
        return update(state,
            { input: { $merge: obj } }
        );
    }
    case STAIRS_GET_OBJECT:
        return {
            ...state,
            item: action.payload,
            input: action.payload
        };
    case STAIRS_GET_OBJECTS:
        return {
            ...state,
            items: action.payload
        };
    case STAIRS_CREATE_OBJECT:
        return {
            ...state,
            items: [...state.items, action.payload],
            input: {}
        };
    case STAIRS_DELETE_OBJECT:
        return {
            ...state,
            items: state.items.filter(item => action.payload !== item.id)
        };
    case STAIRS_UPDATE_OBJECT:
    {
        let obj = {};
        obj[action.payload.id] = action.payload;
        return update(state,
            { items: { $merge: obj }, input: {} }
        );
    }
    case STAIRS_NEW_OBJECT:
        return ({ ...state, input: _.cloneDeep(INITIAL_STATE.input) });
    case STAIRS_GET_RANKING:
        return ({ ...state, ranking: action.payload });
    default:
        return state;
    }
};
