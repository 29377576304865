import { AMCacheActions } from '@app-masters/redux-lib';
import { Http } from '@app-masters/js-lib';
let config = {
    typePrefix: 'STAIRS',
    endPoint: '/stairs/',
    cacheStrategy: 'CacheOnline',
    defaultSort: 'title',
    defaultPopulate: null,
    singularTitle: 'Escada',
    pluralTitle: 'Escadas'
};

config.validateObject = (item) => {
    if (!item.title.length) {
        return 'Favor preencher o nome da escadaria';
    }
    if (!item.location.length) {
        return 'Favor preencher o local da escadaria';
    }
    if (!item.floors.length) {
        return 'Favor preencher a quantidade de andares da escadaria';
    }
    return null;
};

const amactions = new AMCacheActions(config);
amactions.getRanking = () => (dispatch) => {
    Http.get('/ranking/stairs')
        .then(r => {
            console.log(r);
            dispatch({ type: 'STAIRS_GET_RANKING', payload: r });
        });
};

export default amactions;
