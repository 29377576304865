import { AUTH_USER_LOGIN, AUTH_USER_LOGOUT, AUTH_LOGIN_FAIL, AUTH_INIT, AUTH_USER_UPDATED } from '../actions/types';
import { Http } from '@app-masters/js-lib';

export const doLogin = ({ email, password }) => dispatch => {
    dispatch({ type: AUTH_INIT, payload: true });
    Http.post('/login', { email, password })
        .then(r => {
            localStorage.setItem('user', JSON.stringify(r));
            Http.setHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'client': 'admin',
                'Authorization': r.token
            });
            Http.setEndpointParam('{_id}', r.user._id);
            dispatch({ type: AUTH_USER_LOGIN, payload: r });
            dispatch({ type: AUTH_INIT, payload: false });
        })
        .catch(r => {
            localStorage.clear();
            dispatch({ type: AUTH_LOGIN_FAIL, payload: r.message });
            dispatch({ type: AUTH_INIT, payload: false });
        });
};
export const validateEmail = (email) => {
    return (dispatch) => {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(email)) {
            dispatch({ type: AUTH_LOGIN_FAIL, payload: 'Email Invalido' });
            return false;
        } else {
            dispatch({ type: AUTH_LOGIN_FAIL, payload: undefined });
            return true;
        }
    };
};
export const checkLocalStorageForUser = () => {
    return (dispatch) => {
        const auth = JSON.parse(localStorage.getItem('user'));
        if (auth) {
            Http.setHeaders({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': auth.token
            });
            Http.setEndpointParam('{_id}', auth.user._id);
            if (auth.local) {
                auth.email = auth.local.email;
                delete auth.local;
            }
            dispatch({ type: AUTH_USER_LOGIN, payload: auth });
        } else {
            dispatch({ type: AUTH_USER_LOGOUT });
        }
    };
};
export const doLogOut = (props) => (dispatch) => {
    localStorage.clear();
    dispatch({ type: AUTH_USER_LOGOUT });
};
export const updateUser = (updatedUser) => async (dispatch) => {
    let user = JSON.parse(localStorage.getItem('user'));
    console.log(updatedUser);
    if (!updatedUser) {
        updatedUser = await Http.get('/user/{_id}');
    }
    user.user = updatedUser;
    localStorage.setItem('user', JSON.stringify(user));
    dispatch({ type: AUTH_USER_UPDATED, payload: updatedUser });
};
