import React, { Component } from 'react';
import { Http, Text } from '@app-masters/js-lib';
import CircularProgress from 'material-ui/CircularProgress';
import qrIcon from '../images/qrcode.png';
import RaisedButton from 'material-ui/RaisedButton';
import { CardText } from 'material-ui/Card';
import { setTitle } from '../actions/appBarActions';
import { connect } from 'react-redux';

// var isCordovaApp = document.URL.indexOf('http://') === -1
//   && document.URL.indexOf('https://') === -1;

class Escada extends Component {
    constructor (props) {
        super(props);

        this.state = {
            title: '',
            floors: '',
            location: '',
            token: '',
            ranking: null
        };
    }

    componentDidMount () {
        this.props.setTitle('', true, '/escadas');
        this.getInfo();
        // Http.get(`/stairs/${this.props.match.params.id}/ranking`)
        //     .then(ranking => { this.setState({ ranking }); });
    }

    getInfo = () => {
        Http.get(`/stairs/${this.props.match.params.id}`).then(response => {
            this.setState({
                title: response.title,
                floors: response.floors,
                location: response.location,
                user: response.user
            }, () => this.props.setTitle(Text.shorten(this.state.title, 25), true, '/escadas'));
        }).catch(error => console.log('ERROR ==> ', error));
    };

    render () {
        return (
            <React.Fragment>
                <CardText style={{ textAlign: 'center' }}>
                    {
                        this.state.title
                            ? <div style={{ padding: 16 }}>
                                <h2>Escadaria {this.state.title}</h2>
                                {this.state.floors} Andares<br />
                                Localizado em {this.state.location}<br />
                                <br />
                            </div>
                            : <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <CircularProgress size={30} thickness={3} />
                            </div>
                    }

                </CardText>
                {/* { */}
                {/*! this.state.ranking */}
                {/* ? <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                {/* <CircularProgress size={30} thickness={3} /> */}
                {/* </div> */}
                {/*: this.state.ranking.length === 0 */}
                {/* ? <h3 style={{ marginLeft: 8 }}>Seja o primeiro a subir esta escada!</h3> */}
                {/*: <div style={{ padding: 16 }}> */}
                {/* <h3>Ranking da escadaria</h3> */}
                {/* {this.state.ranking.map((item, i) => { */}
                {/* return <div style={{ display: 'flex', width: '100%', padding: '14px 0' }}> */}
                {/* <div style={{ flex: 1 }}> */}
                {/* {i + 1}º */}
                {/* </div> */}
                {/* <div style={{ flex: 7 }}> */}
                {/* {item.user.name || 'Usuário sem nome'} */}
                {/* </div> */}
                {/* <div> */}
                {/* {item.points} */}
                {/* <small style={{ marginLeft: 3, color: '#888' }}>pts</small> */}
                {/* </div> */}
                {/* </div>; */}
                {/* })} */}
                {/* </div> */}
                {/* } */}
                {this.props.user && this.state.user === this.props.user._id
                    ? <CardText>
                        {window.outerWidth > 768 ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <RaisedButton primary label='imprimir qr codes'
                                href={`/qrcode_${this.props.match.params.id}`} labelPosition='before'
                                icon={<img src={qrIcon} style={{ width: 30, height: 30 }} alt='Icon' />} />
                        </div> : <div style={{ display: 'flex', justifyContent: 'center' }}>Acesse por um computador para imprimir os QRCodes.</div>}
                    </CardText>
                    : <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <p>Se essa escada estiver sem os QR Codes, peça para a pessoa que incluiu a escada imprimir eles</p>
                    </div>
                }

            </React.Fragment>
        );
    }
}

const mapActions = {
    setTitle
};

const mapState = ({ authReducer }) => ({
    user: authReducer.user
});

export default connect(mapState, mapActions)(Escada);
