import React, { Component } from 'react';
import { Http } from '@app-masters/js-lib';
import qrImage from 'qr-image';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import { Card, CardText } from 'material-ui/Card';
import { setTitle } from '../actions/appBarActions';
import { connect } from 'react-redux';
import { getQRUrl } from '../config';

// var isCordovaApp = document.URL.indexOf('http://') === -1
//   && document.URL.indexOf('https://') === -1;

class QRCodes extends Component {
    constructor () {
        super();

        this.state = {
            stair: '5a3168e1f60a471f02fb92f5',
            token: '',
            stairs: [],
            stairsFloors: [],
            qrCodeStrs: [],
            QRCodes: null,
            showQRCodes: false,
            print: false
        };
    }

    componentDidMount () {
        this.props.setTitle('QRCodes', true, '/escadas');
        this.renderFloors();
    }

    renderFloors = () => {
        // console.log('ok')
        // console.log(this.props.match.params)

        Http.get(`/floor?stairs=${this.props.match.params.stairs}`).then(response => {
            console.log('->', response);
            this.setState({ stairsFloors: response }, () => this.generateFloorString());
        }).catch(error => console.log('ERROR ==>', error));
    };

    generateFloorString = () => {
        const base = getQRUrl() || window.location.host;
        let str = this.state.stairsFloors.map(data => {
            return {
                str: `${base}/${data.stairs}-${data.id}`
            };
        });
        this.setState({ qrCodeStrs: str }, () => this.generateQRSvg());
    };

    generateQRSvg = () => {
        let qr = this.state.qrCodeStrs.map(data => {
            return qrImage.svgObject(data.str, { type: 'svg', margin: 0 });
        });
        this.setState({ QRCodes: qr, showQRCodes: true });
    };

    renderQRCode () {
        // console.log(this.state.QRCodes)
        const Svgzinho = ({ data }) => {
            return <svg viewBox={`0 0 ${data.size} ${data.size}`} style={{ width: '100%', height: '100%' }} >
                <path d={data.path} />
            </svg>;
        };
        return (
            <div>
                {
                    this.state.QRCodes.map((data, index) => {
                        // console.log(data.path)
                        return (
                            <div
                                key={index}
                                id={`divToPrint${index}`}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#f5f5f5',
                                    flex: 1,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    pageBreakAfter: 'always',
                                    padding: 10
                                }}>
                                <h1>{index + 1}° Andar </h1>
                                {
                                    // IMPRESSÃO 180 180
                                    // Centro view -70 -70
                                }

                                <div style={{ maxWidth: '800px' }}>
                                    <Svgzinho data={data} />
                                    <center>
                                        <h2>Use as escadas, ganhe pontos e troque por descontos!</h2>
                                        <h1>Baixe o App "Tô Subindo"</h1>
                                        <h2>http://bit.ly/tosubindo</h2>
                                        <h4><b>Em desenvolvimento pela App Masters</b></h4>
                                    </center>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    // async printDocument() {
    //     let cont = this.state.stairsFloors.length;
    //     let cont2 = 0;
    //     for (let i = 0; i < this.state.stairsFloors.length; i++) {
    //         const input = document.getElementById(`divToPrint${i}`);
    //         await html2canvas(input)
    //             .then((canvas) => {
    //                 const pdf = new jsPDF();
    //                 const imgData = canvas.toDataURL('image/png');
    //                 pdf.addImage(imgData, 'JPEG', 0, 0);
    //                 pdf.save(`andar${i + 1}.pdf`);
    //                 cont2 += 1;
    //             })
    //         ;
    //     }
    //     console.log('==>', cont, cont2);
    //     if (cont === cont2) {
    //         this.setState({print: false});
    //     }
    // }

    render () {
        if (window.outerWidth < 768) {
            return (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    marginTop: 40
                }}>
                    <Card style={{ marginLeft: 60, marginRight: 60 }}>
                        <CardText>
                            Entre em um computador para poder realizar a impressão dos QRCodes !
                        </CardText>
                    </Card>
                </div>
            );
        }
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <RaisedButton primary label="Download" onClick={() => {this.setState({print:true}, () => this.printDocument())}} /> */}
                </div>
                <div id='divToPrint'>
                    {
                        this.state.showQRCodes
                            ? this.renderQRCode()
                            : null
                    }
                </div>
            </div>
        );
    }
}

const mapActions = {
    setTitle
};
export default connect(null, mapActions)(QRCodes);
