import { AUTH_USER_LOGIN, AUTH_USER_LOGOUT, AUTH_LOGIN_FAIL, AUTH_INIT, AUTH_USER_UPDATED } from '../actions/types';
import update from 'immutability-helper';

const initState = {
    user: null,
    token: '',
    error: '',
    loading: false
};

export default (state = initState, action) => {
    switch (action.type) {
    case AUTH_LOGIN_FAIL:
        return {
            ...state,
            error: action.payload
        };
    case AUTH_USER_LOGIN:
        return update(state, {
            token: { $set: action.payload.token },
            user: { $set: action.payload.user }
        });
    case AUTH_USER_LOGOUT:
        return {
            ...initState
        };
    case AUTH_INIT:
        return {
            ...state,
            loading: action.payload
        };
    case AUTH_USER_UPDATED:
        return {
            ...state,
            user: { ...action.payload }
        };
    default:
        return {
            ...state
        };
    }
};
