import { TITLE_CHANGED } from '../actions/types';
const init = {
    title: 'Tô Subindo!',
    showBack: false,
    backLink: '/'
};
export default (state = init, action) => {
    switch (action.type) {
    case TITLE_CHANGED:
        return action.payload;
    default:
        return state;
    }
};
