// appBar
export const TITLE_CHANGED = 'TITLE_CHANGED';
export const TITLE_RESET = 'TITLE_RESET';

// stairs action types
export const STAIRS_DELETE_OBJECT = 'STAIRS_DELETE_OBJECT';
export const STAIRS_CREATE_OBJECT = 'STAIRS_CREATE_OBJECT';
export const STAIRS_UPDATE_OBJECT = 'STAIRS_UPDATE_OBJECT';
export const STAIRS_NEW_OBJECT = 'STAIRS_NEW_OBJECT';
export const STAIRS_GET_OBJECT = 'STAIRS_GET_OBJECT';
export const STAIRS_GET_OBJECTS = 'STAIRS_GET_OBJECTS';
export const STAIRS_INPUT_CHANGED = 'STAIRS_INPUT_CHANGED';
export const STAIRS_LOADING = 'STAIRS_LOADING';
export const STAIRS_LOADING_ONLINE = 'STAIRS_LOADING_ONLINE';
export const STAIRS_ERROR = 'STAIRS_ERROR';
export const STAIRS_VALID = 'STAIRS_VALID';
export const STAIRS_SAVE_OBJECT = 'STAIRS_SAVE_OBJECT';

// auth action types
export const AUTH_USER_LOGIN = 'AUTH_USER_LOGIN';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_INIT = 'AUTH_INIT';
export const AUTH_USER_UPDATED = 'AUTH_USER_UPDATED';
export const STAIRS_GET_RANKING = 'STAIRS_GET_RANKING';
