import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import listen from 'redux-action-listeners';
import { ActionEmitter } from '@app-masters/redux-lib';
import { AMStorage } from '@app-masters/js-lib';
import logger from 'redux-logger';
import reducers from './reducers';
import history from './history';

let listener = new ActionEmitter();
let storage = new AMStorage(window.localStorage);

const store = createStore(
    reducers,
    {},
    applyMiddleware(
        ReduxThunk,
        listen(listener),
        // listen(listen),
        logger,
        routerMiddleware(history)
    )
);
export { store, listener, storage };
