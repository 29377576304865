import React, { Component } from 'react';
import { connect } from 'react-redux';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';
import { withRouter } from 'react-router-dom';
import { setTitle } from '../actions/appBarActions';
import Home from 'material-ui/svg-icons/action/home';
import User from 'material-ui/svg-icons/social/person';
import Ranking from '../images/trophy.svg';
import Stais from '../images/stais.svg';
import user from '../images/user.png';
import { Styles } from '../styles';
import { auth } from '../actions';
import InfoIcon from 'material-ui/svg-icons/action/info-outline';
import ExitIcon from 'material-ui/svg-icons/action/exit-to-app';

class LeftDrawer extends Component {
    constructor (props) {
        super();
        this.state = {
            email: '',
            id: '',
            name: '',
            user: null,
            perfilPic: user
        };
    }

    render () {
        return (
            <div>
                <Drawer docked={false} onRequestChange={() => this.props.onToggleDrawer(this.props.drawerOpen)}
                    open={this.props.drawerOpen}>
                    <div style={Styles.infoDrawer}>
                        {this.props.user && <div>
                            <div style={Styles.avatar2}>
                                <img
                                    style={{ width: 48, height: 48, objectFit: 'cover' }}
                                    src={this.state.perfilPic}
                                    alt={'Imagem não carregada'}
                                />
                            </div>
                            <span style={{ fontSize: 14, color: '#fff', display: 'block' }}>{this.props.user.name}</span>
                            <span style={{ fontSize: 12, color: '#fff' }}>{this.props.user.email}</span>
                        </div>}
                    </div>
                    <MenuItem
                        value='1'
                        primaryText='Início'
                        leftIcon={<Home />}
                        onClick={() => {
                            this.props.history.push('/');
                            this.props.onToggleDrawer(this.props.drawerOpen);
                        }}
                    />
                    <MenuItem
                        value='2'
                        primaryText='Escadas'
                        leftIcon={<img src={Stais} alt='Escadas' />}
                        onClick={() => {
                            this.props.history.push('/escadas');
                            this.props.onToggleDrawer(this.props.drawerOpen);
                        }}
                    />
                    <MenuItem
                        value='3'
                        primaryText='Ranking'
                        leftIcon={<img src={Ranking} alt='Ranking' />}
                        onClick={() => {
                            this.props.history.push('/rank');
                            this.props.onToggleDrawer(this.props.drawerOpen);
                        }}
                    />
                    <MenuItem
                        value='4'
                        primaryText='Perfil'
                        leftIcon={<User />}
                        onClick={() => {
                            const route = this.props.user ? '/perfil' : '/login';
                            this.props.history.push(route);
                            this.props.onToggleDrawer(this.props.drawerOpen);
                        }}
                    />
                    <MenuItem
                        value='5'
                        primaryText='Sobre'
                        leftIcon={<InfoIcon />}
                        onClick={() => {
                            this.props.onToggleDrawer(this.props.drawerOpen);
                            this.props.history.push('/sobre');
                        }}
                    />
                    {this.props.user ? <div style={{ width: '100%', position: 'absolute', bottom: 0 }}>
                        <MenuItem
                            value='6'
                            primaryText='Sair'
                            leftIcon={<ExitIcon />}
                            onClick={() => {
                                this.props.onToggleDrawer(this.props.drawerOpen);
                                this.props.doLogOut();
                                this.props.history.push('/');
                            }}
                        />
                    </div> : null}
                </Drawer>
            </div>
        );
    }
}

const mapActions = {
    setTitle,
    doLogOut: auth.doLogOut
};
const mapStateToProps = ({ authReducer }) => ({
    ...authReducer
});
export default withRouter(connect(mapStateToProps, mapActions)(LeftDrawer));
