import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import { Http } from '@app-masters/js-lib';
import TextField from 'material-ui/TextField';
import img1 from '../images/user.png';
import { setTitle } from '../actions/appBarActions';
import { connect } from 'react-redux';
import { Styles } from '../styles';
import CircularProgress from 'material-ui/CircularProgress';
import { auth } from '../actions';

class Perfil extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            id: '',
            name: '',
            token: '',
            perfilPic: img1,
            editing: false,
            points: null,
            ranking: null
        };
    }
    componentDidMount () {
        // if (this.props.user && !this.state.ranking) {
        //     Http.get(`/user/${this.props.user._id}/ranking`)
        //         .then(ranking => {
        //             this.setState({ ranking });
        //         });
        // }
    }
    componentDidUpdate () {
        // if (this.props.user && !this.state.ranking) {
        //     Http.get(`/user/${this.props.user._id}/ranking`)
        //         .then(ranking => {
        //             this.setState({ ranking });
        //         });
        // }
    }
    editInfo = () => {
        Http.put(`/user/${this.state.user}`,
            {
                name: this.state.name,
                local: { email: this.state.email }
            })
            .then((response) => {
                response.email = response.local.email;
                delete response.local;
                this.props.updateUser(response);
            }).catch(e => {
                console.log(e);
            });
    };
    render () {
        if (!this.props.user) {
            return <div style={{ height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>;
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 16,
                    marginRight: 16,
                    marginTop: 20
                }}>
                    <div style={Styles.avatar}>
                        <img
                            style={{ objectFit: 'cover', width: 120, height: 120 }}
                            src={this.state.perfilPic}
                            alt={'Imagem não carregada'}
                        />
                    </div>

                    {
                        this.state.editing
                            ? <div style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginLeft: 10
                            }}>
                                <TextField
                                    style={{ width: '100%', height: 42 }}
                                    value={this.state.name}
                                    onChange={(e, name) => this.setState({ name })}
                                    hintText='Nome'
                                />
                                <TextField
                                    style={{ width: 'auto', height: 42 }}
                                    value={this.state.email}
                                    onChange={(e, email) => this.setState({ email })}
                                    hintText='Email'
                                />
                                <RaisedButton
                                    fullWidth
                                    secondary
                                    label='salvar'
                                    onClick={() => {
                                        this.setState({ editing: false }, () => this.editInfo());
                                    }}
                                />
                            </div>
                            : <div style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                                marginTop: 10,
                                marginLeft: 10
                            }}>
                                <h3 style={{ margin: 10, marginLeft: 0 }}>{this.props.user.name}</h3>
                                <h5 style={{ margin: 10, marginLeft: 0 }}>{this.props.user.email}</h5>
                                <RaisedButton
                                    fullWidth
                                    secondary
                                    label='editar'
                                    onClick={() => {
                                        this.setState({ editing: true, email: this.props.user.email, token: this.props.token, user: this.props.user._id, name: this.props.user.name });
                                    }}
                                />
                            </div>
                    }
                </div>
                {/* {this.props.user.pointsTotal !== 0 && <div style={{ flex: 1, display: 'flex', marginTop: 30 }}> */}
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> */}
                {/* <span style={{ fontWeight: 'bold', fontSize: 20 }}>Ranking</span> */}
                {/* <span style={{ marginTop: 10, fontSize: 18 }}>{this.props.user.rankingPosition ? this.props.user.rankingPosition + 'º' : 'Calculando...'}</span> */}
                {/* </div> */}
                {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}> */}
                {/* <span style={{ fontWeight: 'bold', fontSize: 20 }}>Pontos</span> */}
                {/* <span style={{ marginTop: 10, fontSize: 18 }}>{this.props.user.pointsTotal}</span> */}
                {/* </div> */}
                {/* </div>} */}
                {/* { */}
                {/*! this.state.ranking */}
                {/* ? <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                {/* <CircularProgress size={30} thickness={3} /> */}
                {/* </div> */}
                {/*: this.state.ranking.length === 0 */}
                {/* ? <h3>Suba sua primeira escada para ver sua posição</h3> */}
                {/*: <div style={{ margin: 16 }}> */}
                {/* <h3>Seu ranking nas escadas</h3> */}
                {/* {this.state.ranking.map((item, i) => { */}
                {/* return <div style={{ display: 'flex', width: '100%', padding: '6px 0' }}> */}
                {/* <div style={{ width: 35 }}> */}
                {/* {i + 1}º */}
                {/* </div> */}
                {/* <div style={{ flex: 1 }}> */}
                {/* {item.title} */}
                {/* </div> */}
                {/* <div> */}
                {/* {item.points} */}
                {/* <small style={{ marginLeft: 3, color: '#888' }}>pts</small> */}
                {/* </div> */}
                {/* </div>; */}
                {/* })} */}
                {/* </div> */}
                {/* } */}
                <RaisedButton
                    fullWidth
                    secondary
                    style={Styles.raisedButton}
                    label='sair'
                    onClick={() => {
                        this.props.doLogOut();
                        this.props.history.push('/');
                    }}
                />
            </div>
        );
    }
}

const mapActions = {
    setTitle,
    doLogOut: auth.doLogOut,
    updateUser: auth.updateUser
};
const mapStateToProps = ({ authReducer }) => ({ ...authReducer });
export default connect(mapStateToProps, mapActions)(Perfil);
