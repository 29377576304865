import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import { Http, Rollbar } from '@app-masters/js-lib';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import Snackbar from 'material-ui/Snackbar';
import { CardText } from 'material-ui/Card';
import { setTitle } from '../actions/appBarActions';
import ThumbUp from 'material-ui/svg-icons/action/thumb-up';

import { connect } from 'react-redux';
import { auth } from '../actions';
import { Styles } from '../styles';
import { getQRUrl } from '../config';
import PermissionHandler from '../components/PermissionHandler';
const coin = require('../coin.mp3');
const oneUp = require('../1up.mp3');
// eslint-disable-next-line
const coinAudio = new Audio(coin);
// eslint-disable-next-line
const oneUpAudio = new Audio(oneUp);

class Dashboard extends Component {
    constructor (props) {
        super(props);
        this.state = {
            delay: 250,
            floor: null,
            stairs: null,
            user: null,
            list: [],
            points: null,
            m2: false,
            m3: false,
            m4: false,
            sendRise: true,
            stair: '',
            playingSong: false,
            sendingQRCode: false,
            networkError: false,
            errorSnack: '',
            scanQueue: [],
            online: navigator.onLine
        };
        this.handleScan = this.handleScan.bind(this);
        this.handleNextScan = this.handleNextScan.bind(this);
        this.postRise = this.postRise.bind(this);
        this.componentCleanup = this.componentCleanup.bind(this);
    }

    async componentDidMount () {
        window.addEventListener('online', () => { this.setState({ online: true }); this.handleNextScan(); });
        window.addEventListener('offline', () => { this.setState({ online: false }); });
        window.addEventListener('scanned', this.handleNextScan);
        window.addEventListener('beforeunload', this.componentCleanup);
        this.props.setTitle('Tô Subindo!', false);
        const queue = JSON.parse(localStorage.getItem('queue')) || [];
        await this.setState({ scanQueue: queue });
        const hasUsed = !!localStorage.getItem('hasUsed');
        if (window.cordova && !hasUsed) {
            this.props.history.push('/bemVindo');
        }
        if (queue.length > 0) {
            this.handleNextScan();
        }
    }
    componentCleanup () {
        const queue = this.state.scanQueue;
        localStorage.setItem('queue', JSON.stringify(queue || []));
    }
    componentWillUnmount () {
        this.componentCleanup();
        window.removeEventListener('online', () => { this.setState({ online: true }); this.handleNextScan(); });
        window.removeEventListener('offline', () => { this.setState({ online: false }); });
        window.removeEventListener('scanned', this.handleNextScan);
        window.removeEventListener('beforeunload', this.componentCleanup);
    }
    async handleNextScan (event) {
        return new Promise(async (resolve, reject) => {
            if (this.state.scanQueue.length > 0) {
                let { scanQueue } = this.state;
                try {
                    const nUser = await Http.post('/queue', scanQueue);
                    oneUpAudio.play();
                    this.setState({ scanQueue: [], errorSnack: 'Pontos acumulados computados' });
                    this.props.updateUser(nUser);
                    oneUpAudio.play();
                    resolve(nUser);
                } catch (e) {
                    reject(e);
                }
            }
        });
    }
    async handleScan (data) {
        if (data) {
            let keys = data.split('/');
            keys = keys[keys.length - 1];
            const stairs = keys.split('-')[0];
            const floor = keys.split('-')[1];
            if (!floor || !stairs) return;

            if (data.indexOf(getQRUrl()) === -1) {
                return;
            }

            if (this.props.user === null) {
                this.redirectLogin();
                return null;
            }

            const postBody = {
                stairs,
                floor,
                user: this.props.user._id
            };
            let lastFloor = JSON.parse(localStorage.getItem('lastFloor'));
            if (!lastFloor) {
                lastFloor = {
                    floor: '',
                    expiriy: new Date()
                };
            }
            let now = new Date();
            if (floor !== lastFloor.floor || now.getTime() >= new Date(lastFloor.expiry).getTime()) {
                coinAudio.play();
                this.setState({ transition: true });
                setTimeout(() => {
                    this.setState({ transition: false });
                }, 1300);
                try {
                    lastFloor = {
                        floor,
                        expiry: now.setMinutes(now.getMinutes() + 1)
                    };
                    localStorage.setItem('lastFloor', JSON.stringify(lastFloor));
                    if (!this.state.scanQueue.length && !this.state.sendingQRCode) {
                        await this.postRise(postBody);
                        oneUpAudio.play();
                    } else {
                        throw new Error('Sending to queue');
                    }
                } catch (e) {
                    console.log(e);
                    let { scanQueue } = this.state;
                    scanQueue.push(postBody);
                    this.setState({ scanQueue, errorSnack: 'Falha. Pontos foram acumulados' });
                }
            } else {
                this.setState({ errorSnack: 'Aguarde para pontuar este andar novamente' });
            }
        }
    }

    async postRise (body) {
        return new Promise(async (resolve, reject) => {
            try {
                this.setState({ sendingQRCode: true, m2: true });
                const response = await Http.post('/rise', body);
                this.props.updateUser(response);
                // eslint-disable-next-line
                this.setState({ sendingQRCode: false, m3: !!response.data.pointsTotal, m2: false }, () => window.dispatchEvent(new Event('scanned')));
                resolve(response);
            } catch (e) {
                this.setState({ networkError: true, sendingQRCode: false });
                reject(e);
            }
        });
    }

    redirectLogin = () => {
        this.props.history.push('/login');
    }

    renderQRCode (transition) {
        if (transition) return this.renderTranstiion();
        return <QrReader
            delay={this.state.delay}
            onError={(error) => {
                if (error.name === 'NotAllowedError') {
                    Rollbar.log(error);
                    this.props.history.push('/');
                    this.props.history.push('/');
                }
                console.log('error from qr', error);
            }}
            onScan={this.handleScan}
            style={{ height: '100%', width: '100%' }}
        />;
    }

    renderTranstiion () {
        return <div style={
            {
                height: '100%',
                width: '100%',
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ef6c00',
                fontSize: 27,
                fontWeight: 500
            }
        }>
            <ThumbUp style={{ color: '#ef6c00', margin: 16 }} />
            {'Ok, continue!'}
        </div>;
    }

    Snackbars = () => {
        return (
            <div>
                <Snackbar
                    open={this.state.networkError}
                    message={'Falha ao enviar QR Code. Você está offline?'}
                    autoHideDuration={3000}
                    onRequestClose={() => this.setState({ networkError: false })}
                />
                <Snackbar
                    open={this.state.m2}
                    message={'QR Code sendo enviado!'}
                    autoHideDuration={500}
                    onRequestClose={() => this.setState({ m2: false })}
                />
                <Snackbar
                    open={this.state.m3}
                    message={'Você ganhou pontos com essa escada!'}
                    autoHideDuration={1500}
                    onRequestClose={() => this.setState({ m3: false })}
                />
                <Snackbar
                    open={!!this.state.errorSnack}
                    message={this.state.errorSnack}
                    autoHideDuration={2000}
                    onRequestClose={() => this.setState({ errorSnack: '' })}
                />
            </div>
        );
    }

    Content = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                backgroundColor: '#fafafa'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: window.innerHeight - 184,
                    justifyContent: 'center'
                }}>
                    <div style={{ height: 'auto', width: (window.innerWidth > window.innerHeight ? window.innerHeight - 184 : window.innerWidth) }}>

                        {
                            this.renderQRCode(this.state.transition)
                        }
                    </div>
                    {
                        this.props.user
                            ? (!this.state.transition ? <div
                                onClick={() => this.props.history.push('/rank')}
                                style={{
                                    position: 'fixed',
                                    bottom: 0,
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: 80,
                                    backgroundColor: 'white',
                                    zIndex: 999,
                                    boxShadow: 'rgba(0, 0, 0, 0.12) 0px -2px 6px'
                                }}>
                                <div>
                                    {
                                        this.props.user.data.pointsTotal === null
                                            ? <div style={{ paddingBottom: 5 }}>
                                                <CircularProgress size={30} thickness={3} />
                                            </div>
                                            : <CardText style={{ lineHeight: 0, padding: 9, textAlign: 'center' }}>
                                                {!this.state.online ? <div style={{ lineHeight: 1 }}>Sem conexão. Use assim mesmo.<br /> Seus pontos serão atualizados automaticamente quando ficar online</div> : null}
                                                <h1>{(this.props.user.data.pointsTotal >= 2) ? this.props.user.data.pointsTotal : 0}</h1>
                                                <h3 style={{ paddingTop: 8 }}>Pontos</h3></CardText>
                                    }
                                </div>
                            </div> : null)
                            : <div style={{
                                position: 'fixed',
                                bottom: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingBottom: 25,
                                backgroundColor: 'white',
                                height: 55,
                                zIndex: 999
                            }}>
                                <h5 style={{ lineHeight: 0 }}>Para contabilizar pontos realize login</h5>
                                <RaisedButton
                                    fullWidth
                                    secondary
                                    style={Styles.raisedButton}
                                    label='login'
                                    onClick={() => {
                                        this.props.history.push('/login');
                                    }}
                                />
                            </div>
                    }
                </div>
            </div>
        );
    }
    render () {
        return (
            <PermissionHandler>
                <this.Snackbars />
                <this.Content />
            </PermissionHandler>
        );
    }
}

const mapActions = {
    setTitle,
    updateUser: auth.updateUser
};

export default connect(({ authReducer }) => ({ ...authReducer }), mapActions)(Dashboard);
