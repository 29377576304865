import React, { Component } from 'react';
import { connect } from 'react-redux';
import RaisedButton from 'material-ui/RaisedButton';
import { Card, CardText } from 'material-ui/Card';
import { setTitle } from '../actions/appBarActions';
import { escada } from '../actions';
import { Text } from '@app-masters/js-lib';

import CircularProgress from 'material-ui/CircularProgress';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import { Styles } from '../styles';
class Escadas extends Component {
    constructor (props) {
        super(props);
        this.state = {
            qrCodeStrs: [],
            QRCodes: null,
            showQRCodes: false,
            loading: true,
            noGet: false
        };
    }

    componentDidMount () {
        this.props.getObjects();
        this.props.setTitle('Escadas', true, '/');
    }
    createStair () {
        const route = this.props.user ? '/cadastrarEscada' : '/login';
        this.props.history.push(route);
    }
    render () {
        if (this.props.error) {
            return (
                <div style={{ marginTop: '10px' }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        marginTop: 40
                    }}>
                        <Card style={{ marginLeft: 30, marginRight: 30 }}>
                            <CardText>
                                Não foi possível carregar as escadas, cheque sua internet e tente novamente!
                            </CardText>
                        </Card>
                        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <RaisedButton primary label='Cadastrar escada'
                                onClick={() => this.createStair()} />
                        </div>
                    </div>
                </div>
            );
        }
        if (this.props.loading && !this.props.items.length) {
            return <div style={{ height: '100%', width: '100%', position: 'fixed', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </div>;
        }
        return (
            <div>
                {
                    <Table >
                        <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
                            <TableRow>
                                <TableHeaderColumn>Edifício</TableHeaderColumn>
                                <TableHeaderColumn style={{ textAlign: 'right' }}>Andares</TableHeaderColumn>
                            </TableRow>
                        </TableHeader>
                        <TableBody displayRowCheckbox={false}>
                            {
                                this.props.items.map((data, index) => {
                                    return (
                                        <TableRow key={index} selected={false}>
                                            <TableRowColumn>
                                                <div style={{ display: 'flex', alignItems: 'center', fontSize: 18, userSelect: 'none', cursor: 'pointer' }}>
                                                    <div
                                                        onClick={() => {
                                                            this.props.history.push(`/escada_${data._id}`);
                                                        }}
                                                        style={{ cursor: 'pointer', display: 'inline', marginRight: 5 }} />
                                                    <span style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.props.history.push(`/escada_${data._id}`);
                                                        }}
                                                    >{Text.ucWords(data.title || '')} </span>
                                                </div>
                                            </TableRowColumn>
                                            <TableRowColumn style={{ textAlign: 'right', width: 25, cursor: 'pointer', fontSize: 18 }}>
                                                <div onClick={() => {
                                                    this.props.history.push(`/escada_${data._id}`);
                                                }}>{data.floors}</div>
                                            </TableRowColumn>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                }
                <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#fff' }}>
                    <RaisedButton
                        fullWidth
                        secondary
                        style={Styles.raisedButton}
                        label='Cadastrar escada'
                        onClick={() => this.createStair()}
                    />
                </div>
            </div>
        );
    }
}

const mapActions = {
    setTitle,
    getObjects: escada.getObjects

};
const mapStateToProps = ({ escadaReducer, authReducer }) => {
    return { ...escadaReducer, ...authReducer };
};
export default connect(mapStateToProps, mapActions)(Escadas);
