import React from 'react';
// import { Login, WelcomeScreen, User } from '../screens';
import Login from '../screens/Login';
import User from '../screens/User';
import Escadas from '../screens/Escadas';
import Dashboard from '../screens/Dashboard';
import Escada from '../screens/Escada';
import Perfil from '../screens/Perfil';
import Rank from '../screens/Rank';
import Sobre from '../screens/Sobre';
import CadastrarEscada from '../screens/CadastrarEscada';
import QRCodes from '../screens/QRCodes';
import { Route, Redirect, Switch } from 'react-router-dom';
import QRRedirect from '../screens/QRRedirect';
import BemVindo from '../screens/BemVindo';

export default () => (
    <Route path='/'>
        <Switch>
            <Route exact path={'/:id1-:id2'} component={QRRedirect} />
            <Route exact path={'/'} component={Dashboard} />
            <Route exact path={'/cadastrarescada'} component={CadastrarEscada} />
            <Route exact path={'/escadas'} component={Escadas} />
            <Route exact path={'/perfil'} component={Perfil} />
            <Route exact path={'/login'} component={Login} />
            <Route exact path={'/qrcode_:stairs'} component={QRCodes} />
            <Route exact path={'/rank'} component={Rank} />
            <Route exact path={'/escada_:id'} component={Escada} />
            <Route exact path={'/user_:id'} component={User} />
            <Route exact path={'/sobre'} component={Sobre} />
            <Route exact path={'/bemVindo'} component={BemVindo} />
            {/* <Route exact path={'/'} component={() => (<Redirect to='/dashboard' />)} /> */}
            <Route path='*' component={() => (<Redirect to='/' />)} />
        </Switch>
    </Route>
);
