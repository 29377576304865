import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'material-ui/Card';
import { setTitle } from '../actions/appBarActions';
import { connect } from 'react-redux';
import Divider from 'material-ui/Divider';

class Sobre extends Component {
    componentDidMount () {
        this.props.setTitle('Sobre', true, '/');
    }

    render () {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around'
            }}>
                <Card style={{ margin: '0 15px', boxShadow: 0, textAlign: 'center' }}>
                    <CardTitle title='Tô Subindo!' subtitle='App Masters' />
                    <CardText style={{ padding: 0 }}>
                        Esse aplicativo BETA foi desenvolvido pela App Masters
                        <br />
                        Com o objetivo de estimular um estilo de vida mais saudável, o Tô Subindo dá pontos aos usuários
                        ao subirem (e descerem) escadas. Os pontos acumulados podem ser trocados por descontos em nossos
                        parceiros.
                        <br />
                        <h2>Incentive o uso de escadas!</h2>
                        Se você quiser usar também em seu prédio, basta cadastrar uma nova escada e imprimir os QR Codes
                        para fixar nas paredes dos andares.
                        <br />
                        Para se tornar um parceiro, entre em{' '}
                        <a href='https://api.whatsapp.com/send?phone=5532988735683&text=Quero%20ser%20parceiro'
                            target='_blank' rel='noopener noreferrer'>contato</a> conosco
                    </CardText>

                    <br />
                    <Divider />
                    <br />
                    <a target='_blank' rel='noopener noreferrer' href='https://appmasters.io/pt'
                        style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)', fontWeight: 900 }}>
                        <img src={require('../images/appMastersLogo.png')} style={{ width: '100%' }}
                            alt='Logo da app masters' />
                    </a>
                </Card>
            </div>
        );
    }
}

const mapActions = {
    setTitle
};

export default connect(null, mapActions)(Sobre);
