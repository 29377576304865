function getUrl (rota) {
    console.log(process.env);
    if (process.env.NODE_ENV === 'production') {
        return 'https://going-up-prod.herokuapp.com/api' + rota;
    } else {
        // return 'https://going-up-prod.herokuapp.com/api' + rota;
        return 'http://localhost:3000/api' + rota;
    }
}
function getQRUrl (rota) {
    // console.log(process.env)
    if (process.env.NODE_ENV === 'production') {
        return 'https://to-subindo.firebaseapp.com';
    } else {
        return 'https://localhost:9999';
    }
}

const envs = {};

const rollbarToken = '40ced49e74884c9286a1e5a81779f9c0';

envs.development = {
    baseUrl: 'http://localhost:3000/api',
    // baseUrl: 'http://10.0.2.2:3000/api',
    // baseUrl: 'https://going-up-dev.herokuapp.com/api',
    // baseUrl: 'https://going-up-prod.herokuapp.com/api',
    rollbarToken
};

envs.qrURL = 'https://to-subindo.firebaseapp.com';

envs.development_firebase = {
    // baseUrl: 'https://going-up-dev.herokuapp.com/api',
    baseUrl: 'https://going-up-prod.herokuapp.com/api',
    rollbarToken
};

envs.production = {
    baseUrl: 'https://going-up-prod.herokuapp.com/api',
    // baseUrl: 'https://92.168.254.104:3000/api',
    rollbarToken
};

envs.staging = {
    baseUrl: 'https://goin-up-staging.herokuapp.com/api',
    rollbarToken
};

export { getUrl, getQRUrl };
export default envs;
