import image from '../images/bg_drawer.jpg';

const Styles = {
    flatButton: {
        color: 'white'
    },
    raisedButton: {
        position: 'fixed',
        height: 48,
        bottom: 0,
        left: 0,
        borderRadius: 0
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        height: 120,
        backgroundColor: '#000',
        borderRadius: 10
    },
    infoDrawer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 125,
        backgroundImage: `url(${image})`,
        paddingTop: 24,
        paddingLeft: 16,
        boxSizing: 'border-box'
    },
    avatar2: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        width: 48,
        height: 48,
        marginBottom: 8,
        backgroundColor: '#000',
        borderRadius: 50,
        overflow: 'hidden'
    }
};
export { Styles };
