import React, { Component } from 'react';
import { Http } from '@app-masters/js-lib';
// import Edit from 'material-ui/svg-icons/content/create';
import Email from 'material-ui/svg-icons/content/mail';
import CircularProgress from 'material-ui/CircularProgress';

class User extends Component {
    constructor (props) {
        super(props);
        this.state = {
            email: '',
            id: '',
            name: '',
            token: '',
            perfilPic: 'http://centralwordpress.com.br/wp-content/uploads/whatsapp-imagens-para-perfil-7.jpg',
            points: null
        };
    }

    componentDidMount () {
        let state = localStorage.getItem('user');
        state = JSON.parse(state);
        // console.log(state.user.email)
        this.setState({ token: state.token }, () => this.renderInfo());
    }

    renderInfo = () => {
        Http.get(`/user/${this.props.match.params.id}`).then(response => {
            // console.log('Reponse =>', response);
            if (response.data !== undefined) {
                this.setState({ rank: response.data.rankingPosition });
            }
            this.setState({ email: response.email, name: response.name });
        });

        Http.get(`/rise?user=${this.props.match.params.id}`).then(response => {
            // console.log('->',response.data)
            this.setState({ points: (response.data.pointsTotal) });
        }).catch(error => console.log('ERROR ==> ', error));
    }

    render () {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{
                    marginLeft: 16,
                    marginTop: 20,
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <img
                        style={{ width: 140, height: 100, borderRadius: 20 }}
                        src={this.state.perfilPic}
                        alt={'Imagem não carregada'}
                    />
                    <div style={{
                        marginLeft: 16,
                        marginTop: 20,
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <div style={{
                            marginTop: 20,
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <h5 style={{ marginTop: 30 }}>{this.state.name}</h5>
                            <div style={{ display: 'flex', alignItems: '' }}><Email /><h5
                                style={{ marginTop: 5, paddingLeft: 5 }}>{this.state.email}</h5></div>
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'center'
                }}>
                    <h3>Rank</h3>
                    <h3>Pontos</h3>
                    {/* <h3>Último</h3> */}
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignItems: 'flex-end'
                }}>
                    {
                        this.state.rank
                            ? <h1>{this.state.rank}</h1>
                            : <h1>0</h1>
                    }
                    {
                        this.state.points === null
                            ? <CircularProgress size={30} thickness={3} />
                            : <h1>{this.state.points}</h1>
                    }
                    {/* <h4>0</h4> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }} />
            </div>
        );
    }
}

export default User;
