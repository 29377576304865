import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import ArrowRight from 'material-ui/svg-icons/hardware/keyboard-arrow-right';
import NavigationCheck from 'material-ui/svg-icons/navigation/check';
import image01 from '../images/slider-image-01.jpg';
import image02 from '../images/slider-image-02.jpg';
import image03 from '../images/slider-image-03.jpg';
import { CardText } from 'material-ui/Card';

const styles = {
    root: {
        position: 'fixed',
        zIndex: 9999,
        top: 0,
        left: 0,
        height: window.innerHeight,
        width: window.innerWidth,
        backgroundColor: 'rgb(251, 140, 0)',
        textAlign: 'center'
    },
    swipeable: {
        height: window.innerHeight,
        width: window.innerWidth
    },
    slide: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column',
        padding: 0,
        overflow: 'hidden'
    },
    image: {
        height: 'auto',
        width: '95%',
        transform: 'rotate(-5deg)',
        boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)',
        borderRadius: 5,
        border: '10px solid #fafafa'
    },
    textContainer: {
        padding: 8,
        paddingBottom: 35,
        color: '#f5f5f5',
        fontSize: '1em'
    },
    title: {
        fontSize: '1.2em',
        fontWeight: '600',
        marginBottom: 8,
        color: 'white'
    }

};

class BemVindo extends Component {
    constructor (props) {
        super(props);
        this.state = {
            index: 0
        };
    }
    handleConfirm () {
        localStorage.setItem('hasUsed', true);
        this.props.history.replace('/');
    }
    render () {
        return (
            <div style={styles.root} >
                <SwipeableViews containerStyle={styles.swipeable} index={this.state.index} onChangeIndex={(index) => this.setState({ index })}>
                    <div style={styles.slide}>
                        <img src={image01} style={styles.image} alt='Slide 01' />
                        <CardText style={styles.textContainer}>
                            <div style={styles.title}>Suba de escadas!</div>
                            Praticar exercícios te ajuda a levar uma vida mais saudável.
                        </CardText>
                    </div>
                    <div style={styles.slide}>
                        <img src={image02} style={styles.image} alt='Slide 02' />
                        <CardText style={styles.textContainer}>
                            <div style={styles.title}>Ganhe pontos</div>
                            <span>Cada lance de escadas que subir (ou descer) contam pontos, que ficam em seu perfil</span>
                        </CardText>
                    </div>
                    <div style={styles.slide}>
                        <img src={image03} style={styles.image} alt='Slide 03' />
                        <CardText style={styles.textContainer}>
                            <div style={styles.title}>Troque seus pontos</div>
                            <span>Os pontos acumulados são trocados por descontos em lojas pela cidade</span>
                        </CardText>
                    </div>
                </SwipeableViews>
                <Next onNext={() => this.setState({ index: this.state.index + 1 })} onConfirm={() => this.handleConfirm()} index={this.state.index} size={3} />
                <Pagination index={this.state.index} size={3} />
            </div>
        );
    }
}

const Next = ({ size, index, onNext, onConfirm }) => {
    const nextStyle = {
        position: 'absolute',
        bottom: 20,
        right: 20
    };
    return (
        size - 1 === index
            ? (
                <NavigationCheck onClick={onConfirm} color='white' style={nextStyle} />
            )
            : (
                <ArrowRight onClick={onNext} color='white' style={nextStyle} />
            )
    );
};
const Pagination = ({ size, index }) => {
    const thisStyle = {
        root: {
            position: 'absolute',
            bottom: 15,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        dot: {
            width: 8,
            height: 8,
            backgroundColor: '#aaaaaa',
            margin: '0 6px',
            borderRadius: 10
        },
        selected: {
            backgroundColor: 'white'
        }
    };
    return (
        <div style={thisStyle.root} >
            {Array.apply(null, { length: size }).map((item, ind) => {
                return (
                    <div style={Object.assign({}, thisStyle.dot, (ind === index ? thisStyle.selected : {}))} />
                );
            })}
        </div>
    );
};
export default BemVindo;
