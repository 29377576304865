import React, { Component } from 'react';
import { Rollbar, AppBootstrap, Http } from '@app-masters/js-lib';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import Main from './components/main';
import './App.css';
import { store, storage } from './store';
import Routes from './routes/routes';
import theme from './theme';

import packag from '../package.json';
import envs from './config';
import { AMActions } from '@app-masters/redux-lib';

class App extends Component {
    constructor () {
        super();
        this.state = {
            user: null,
            showSignUpStair: false,
            drawerOpen: false
        };
    }

    componentWillMount () {
        try {
            let callbacks = {
                onMinVersionNotSatifies: (version) => {
                    window.alert('Você deve atualizar sua versão agora! Por favor recarregue a página, se a mensagem continuar, limpe o cache do navegador.');
                },
                onNewVersion: (version) => {
                    if (version === '0.2.0') {
                        // Some new version decision
                    }
                },
                onUncaughtError: (e) => {
                    if (e.message !== 'Failed to fetch') {
                        // Rollbar.error(e);
                        window.alert('Houve um erro inesperado e os programadores responsáveis já foram avisados. \n\n Detalhes técnicos: ' + e.message);
                    } else {
                        window.alert('Falha na conexāo');
                    }
                }
            };
            AppBootstrap.setup('web', packag, envs, storage, callbacks);
            AMActions.setup(storage, callbacks);
            let data = JSON.parse(localStorage.getItem('user'));
            let newHeaders = {
                ...Http.getHeaders(),
                Authorization: (data) ? data.token : ''
            };
            Http.setHeaders(newHeaders);
        } catch (e) {
            Rollbar.log(e);
            window.alert('Houve um erro inesperado e os programadores responsáveis já foram avisados. \n\n Detalhes técnicos: ' + e.message);
        }
    }

    componentDidMount () {
        // document.getElementById("app").requestFullscreen();
        let user = localStorage.getItem('user');
        this.setState({ user });
    }

    render () {
        return (
            <Provider store={store}>
                <MuiThemeProvider muiTheme={theme}>
                    <Router>
                        <Main>
                            <div style={{ paddingTop: 64, height: '100%', paddingBottom: 80, boxSizing: 'border-box' }}>
                                <Routes />
                            </div>
                        </Main>
                    </Router>
                </MuiThemeProvider>
            </Provider>
        );
    }
}

export default App;
