import React, { Component } from 'react';
import { Http, Text } from '@app-masters/js-lib';
import CircularProgress from 'material-ui/CircularProgress';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn
} from 'material-ui/Table';
import { setTitle } from '../actions/appBarActions';
import { connect } from 'react-redux';
import { auth } from '../actions';

class Rank extends Component {
    constructor () {
        super();
        this.state = {
            token: '',
            rank: []
        };
    }

    componentDidMount () {
        this.props.setTitle('Ranking', true, '/');
        this.renderRank();
    }

    renderRank () {
        Http.get('/ranking').then(response => {
            // console.log('->', response.data);
            this.setState({ rank: response });
            response.forEach(user => {
                if (this.props.user && user._id.toString() === this.props.user._id.toString()) {
                    this.props.updateUser(user);
                }
            });
        }).catch(error => console.log('ERROR ==> ', error));
    }

    getColor (pos) {
        if (pos === 0) {
            return {
                color: '#CFB53B'
            };
        }
        if (pos === 1) {
            return {
                color: '#b7b7b7'
            };
        }
        if (pos === 2) {
            return {
                color: '#a17419'
            };
        }
    }

    render () {
        return (
            <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                {
                    this.state.rank
                        ? <Table selectable={false}>
                            <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                <TableRow>
                                    <TableHeaderColumn style={{ width: 35, padding: 8 }}>#</TableHeaderColumn>
                                    <TableHeaderColumn style={{ padding: 8 }}>Usuário</TableHeaderColumn>
                                    <TableHeaderColumn style={{ textAlign: 'right', padding: 8 }}>Pontos</TableHeaderColumn>
                                </TableRow>
                            </TableHeader>

                            <TableBody displayRowCheckbox={false}>
                                {this.state.rank.filter(a => a.data.pointsTotal).map((data, index) => {
                                    let name = data.name || data.email;
                                    // eslint-disable-next-line
                                    name = name.split('@')[0].replace(/[&\/\\#,+()$~%.'":*?<>{}_]/g, ' ').trim();
                                    const userStyle = this.props.user && data._id === this.props.user._id
                                        ? {
                                            fontWeight: '800',
                                            fontSize: 22,
                                            color: 'black'
                                        }
                                        : {
                                            fontWeight: 'normal',
                                            fontSize: 18,
                                            color: '#444'
                                        };
                                    return (
                                        <TableRow style={{ borderBottom: '1px solid rgba(0,0,0,0.2)', ...userStyle }} key={index}>
                                            <TableRowColumn style={{ width: 35, fontSize: 20, padding: 8 }}>{index + 1 + 'º'}</TableRowColumn>
                                            <TableRowColumn style={{ fontSize: 18, padding: 8 }}>{Text.ucWords(name)}</TableRowColumn>
                                            <TableRowColumn style={{ textAlign: 'right', width: 60, fontSize: 18, padding: 8 }}>
                                                {
                                                    data.data ? (data.data.pointsTotal || 0) : 0
                                                }
                                            </TableRowColumn>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        : <CircularProgress size={30} thickness={3} />
                }
            </div>
        );
    }
}

const mapActions = {
    setTitle,
    updateUser: auth.updateUser
};
const mapStateToProps = ({ authReducer }) => ({ ...authReducer });
export default connect(mapStateToProps, mapActions)(Rank);
