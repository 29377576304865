import {
    orange800,
    orange500,
    orange200,
    grey300,
    grey400,
    white,
    fullBlack
} from 'material-ui/styles/colors';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

const muiTheme = getMuiTheme({
    fontFamily: 'Roboto, sans-serif',
    inkBar: {
        backgroundColor: 'yellow' // TO BE DEFINED XDXD
    },
    textField: {
        errorColor: grey400
    },
    palette: {
        primary1Color: orange800,
        accent1Color: orange500,
        textColor: fullBlack,
        alternateTextColor: white,
        canvasColor: white,
        borderColor: grey300,
        pickerHeaderColor: orange200,
        shadowColor: fullBlack
    },
    tableRow: {
        borderColor: '#fff'
    }
});

export default muiTheme;
