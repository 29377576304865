import React, { Component } from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

export default class PermissionHandler extends Component {
    constructor (props) {
        super(props);
        this.state = {
            ready: false,
            permission: !!localStorage.getItem('permission'),
            denied: false,
            loading: true
        };
    }
    cordovaPermission = () => {
        this.setState({ ready: true });
        let { permissions } = window.cordova.plugins;
        permissions.checkPermission(permissions.CAMERA, (status) => {
            this.setState({ permission: !!status.hasPermission, loading: false });
            localStorage.setItem('permission', status.hasPermission ? 'ok' : null);
        });
    }
    async componentDidMount () {
        if (window.cordova) {
            document.addEventListener('deviceready', this.cordovaPermission);
        } else {
            let permissions = await navigator.permissions.query({ name: 'camera' });
            if (permissions.state === 'prompt') {
                this.setState({ permission: false, denied: false, loading: false });
            } else if (permissions.state === 'denied') {
                this.setState({ permission: false, denied: true, loading: false });
                localStorage.removeItem('permission');
            } else if (permissions.state === 'granted') {
                localStorage.setItem('permission', 'ok');
                this.setState({ permission: true, denied: false, loading: false });
            }
        }
    }
    setDeviceReady = () => {
        const permissions = window.cordova.plugins.permissions;
        if (this.state.ready) {
            try {
                permissions.requestPermission(permissions.CAMERA,
                    (res) => {
                        console.log('PERMISSION CORDOVA', res);
                        localStorage.setItem('permission', 'ok');
                        setTimeout(() => this.cordovaPermission(), 500);
                    },
                    (res) => this.setState({ denied: true, permission: false }, () => localStorage.removeItem('permission'))
                );
            } catch (error) {
                this.setState({ status: 'erro!' });
            }
        }
    }
    handlePermission = () => {
        if (window.cordova) {
            this.setDeviceReady();
        } else {
            this.setState({ permission: true, denied: false });
            localStorage.setItem('permission', 'ok');
        }
    }
    render () {
        if (this.state.loading) {
            return <CircularProgress size={30} thickness={3} />;
        }
        if (this.state.permission) {
            return this.props.children;
        }
        if (this.state.denied) {
            return <div style={{ height: window.innerHeight - 64, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
                Acesso à camera negado.<br />
                {window.cordova ? 'Para utilizar o app acesse as configurações do dispositivo e conceda acesso em Configurações -> Apps' : 'Para utilizar o app acesse as configurações do navegador e conceda permissão'}
            </div>;
        }
        return <div style={{ padding: '0 36px', height: window.innerHeight - 64, width: window.innerWidth - 72, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center' }}>
            Este aplicativo requer acesso à camera para ser utilizado. <br />
            <RaisedButton style={{ position: 'absolute', bottom: 0, height: 40, left: 0 }} fullWidth primary label='Conceder acesso' onClick={this.handlePermission} />
        </div>;
    }
}
