import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import appBarReducer from './appBarReducer';
import escadaReducer from './escadaReducer';
import authReducer from './authReducer';

export default combineReducers({
    appBar: appBarReducer,
    router: routerReducer,
    authReducer,
    escadaReducer
});
