import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { auth } from '../actions';
// import { listener } from '../store';
import AppBar from 'material-ui/AppBar';
import ArrowBack from 'material-ui/svg-icons/hardware/keyboard-arrow-left';
import Menu from 'material-ui/svg-icons/navigation/menu';

import LeftDrawer from './leftDrawer';
class Main extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: null,
            drawerOpen: false
        };
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    componentDidMount () {
        this.props.checkLocalStorageForUser();
    }

    toggleDrawer (drawerOpen) {
        this.setState({ drawerOpen: !drawerOpen });
    }

    render () {
        return (
            <div className='app'>
                {this.renderAppBar()}
                {/* {this.state.user ? */}
                <LeftDrawer history={window.history} onToggleDrawer={(drawerOpen) => this.toggleDrawer(drawerOpen)}
                    drawerOpen={this.state.drawerOpen} />
                {/* : null */}
                {/* } */}
                {this.props.children}
            </div>
        );
    }

    iconElementLeft () {
        return this.props.appBar.showBack ? (
            <div style={{
                height: 45,
                width: 45,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }} onClick={() => this.props.history.push(this.props.appBar.backLink)}>
                <ArrowBack style={{ height: 36, width: 36 }} color='white' />
            </div>)
            : (
                <div style={{
                    height: 45,
                    width: 45,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }} onClick={() => this.toggleDrawer(this.state.drawerOpen)}>
                    <Menu style={{ height: 30, width: 30 }} color='white' />
                </div>);
    }

    renderAppBar () {
        return (
            <AppBar className='no-print'
                title={<b style={{ textDecoration: 'none', color: 'white' }}>{this.props.appBar.title}</b>}
                onLeftIconButtonClick={() => {
                    this.toggleDrawer(this.state.drawerOpen);
                }}
                iconElementLeft={this.iconElementLeft()}
                style={{ justifyContent: 'space-around', position: 'fixed', top: 0 }} />
        );
    }
}

const mapStateToProps = ({ appBar, authReducer }) => {
    return { appBar, ...authReducer };
};
const mapActions = {
    checkLocalStorageForUser: auth.checkLocalStorageForUser
};
export default withRouter(connect(mapStateToProps, mapActions)(Main));
