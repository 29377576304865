import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { Http } from '@app-masters/js-lib';
import { Card, CardText } from 'material-ui/Card';
import Dialog from 'material-ui/Dialog';
import CircularProgress from 'material-ui/CircularProgress/CircularProgress';
import { connect } from 'react-redux';
import { setTitle } from '../actions/appBarActions';
import { escada } from '../actions';
import { listener } from '../store';

class CadastrarEscada extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: null,
            userId: '',
            titulo: '',
            localizacao: '',
            andares: '',
            token: null,
            m1: false,
            m2: false,
            loading: false
        };
        listener.on('STAIRS_CREATE_OBJECT', (action) => {
            if (action.payload.created_at) {
                this.props.history.push('/escadas');
            }
        });
        listener.on('AUTH_USER_LOGIN', (action) => {
            this.props.inputChanged('user', action.payload.user._id);
        });
    }

    componentDidMount () {
        this.props.prepareForm();
        if (this.props.user) {
            this.props.inputChanged('user', this.props.user._id);
        }
    }

    handleClose = () => {
        this.setState({ m1: false, m2: false });
    };
    handleInputChange = (event) => {
        let { id, value } = event.target;
        this.props.inputChanged(id, value);
    };
    handleSave = () => {
        this.props.deleteCache();
        let validated = this.props.validateObject(this.props.input);
        // Validate
        if (validated.payload === true) {
            // Create ou update? Let saveObject decide
            this.setState({ loading: true });
            Http.post('/stairs', this.props.input)
                .then(r => {
                    this.props.history.push('/escadas');
                })
                .catch(e => this.setState({ loading: false, error: e.message }));
        } else {
            this.setState({ error: validated.payload.message });
        }
    };

    render () {
        if (this.state.loading) {
            return <div style={{
                height: '100%',
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress />
            </div>;
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                marginTop: 40
            }}>
                <Dialog
                    title='Escada Cadastrada!'
                    modal={false}
                    open={this.state.m1}
                    onRequestClose={() => this.handleClose()}
                />
                <Dialog
                    title={this.state.error}
                    modal={false}
                    open={this.state.m2}
                    onRequestClose={() => this.handleClose()}
                />
                <Card style={{ marginLeft: 60, marginRight: 60 }}>
                    <CardText style={{ textAlign: 'center' }}>
                        {'Se você for responsável por uma escada, cadastre ela aqui para receber os QRCodes, colar em cada andar e ' +
                        'fazer com que ela dê pontos para quem subir'}
                    </CardText>
                </Card>
                <div style={{ marginTop: 40, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField value={this.props.input.title} id='title' onChange={this.handleInputChange}
                        hintText='Nome do Edifício' /><br />
                    <TextField type='number' id='floors' value={this.props.input.floors}
                        onChange={(e, andares) => {
                            andares = Number(andares).toFixed(0);
                            e.target.value = andares;
                            this.handleInputChange(e);
                        }} hintText='Andares' /><br />
                    <div>
                        <TextField value={this.props.input.location}
                            onChange={this.handleInputChange}
                            id='location'
                            hintText='Localização' /><br />
                        <h6 style={{ lineHeight: 0, marginTop: 0, color: '#b3b3b3' }}>Ex: Rua do limoeiro, 180</h6>
                    </div>
                    {
                        this.props.loading
                            ? <CircularProgress />
                            : <RaisedButton onClick={this.handleSave}
                                label='Cadastrar Escadas'
                            />
                    }
                    <CardText style={{ color: '#F44336' }}>
                        {this.state.error}
                    </CardText>
                </div>
            </div>
        );
    }
}

const mapActions = {
    setTitle,
    inputChanged: escada.inputChanged,
    saveObject: escada.saveObject,
    validateObject: escada.validateObject,
    deleteCache: escada.deleteSyncData,
    prepareForm: escada.prepareForm,
    getObjects: escada.getObjects
};
const mapStateToProps = ({ escadaReducer, authReducer }) => {
    const { input, loading, item, error } = escadaReducer;
    return { input, loading, item, error, ...authReducer };
};
export default connect(mapStateToProps, mapActions)(CadastrarEscada);
