import React, { Component } from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { Http } from '@app-masters/js-lib';
import CircularProgress from 'material-ui/CircularProgress';
import { Card, CardText } from 'material-ui/Card';
// import {Link} from 'react-router-dom';
import { auth } from '../actions';
import { connect } from 'react-redux';
import { Styles } from '../styles/index';
import { listener } from '../store';

class Login extends Component {
    constructor () {
        super();
        this.state = {
            email: '',
            loading: false,
            isSigned: false
        };
        this.doLogin = this.doLogin.bind(this);
        this.saveInStorage = this.saveInStorage.bind(this);
        listener.on('AUTH_USER_LOGIN', () => {
            this.props.history.push('/');
        });
    }

    componentDidMount () {
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        if (user !== null) {
            this.setState({ isSigned: true });
        }
    }

    doLogin () {
        if (this.props.validateEmail(this.state.email)) {
            const { email } = this.state;
            this.props.doLogin({ email, password: email.toLowerCase() });
        }
    }

    saveInStorage (data) {
        let user = JSON.stringify(data);
        let newHeaders = {
            ...Http.getHeaders(),
            Authorization: data.token
        };
        Http.setHeaders(newHeaders);
        localStorage.setItem('user', user);
        this.props.history.push('/');
    }

    render () {
        if (this.state.isSigned) {
            return (<div>
                <Card style={{ marginLeft: 60, marginRight: 60, marginTop: 40 }}>
                    <CardText>
                            Você já está logado!
                            Retorne para a página principal
                    </CardText>
                </Card>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                    <RaisedButton primary label='Retornar' onClick={() => this.props.history.push('/')} />
                </div>
            </div>
            );
        }
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 40
            }}>
                <h5 style={{ textAlign: 'center' }}>
                    Para continuar é preciso que você esteja autenticado
                </h5>
                <br />
                <TextField onChange={(e, email) => this.setState({ email }, console.log(email.length))}
                    hintText='Seu endereço de e-mail' /><br />
                {
                    this.props.loading
                        ? <CircularProgress />
                        : <RaisedButton
                            fullWidth
                            secondary
                            style={Styles.raisedButton}
                            onClick={this.doLogin}
                            label='Entrar'
                        />
                }
                <div style={{ lineHeight: 0, color: '#F44336' }}>
                    {this.props.error}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ authReducer }) => ({
    ...authReducer
});
const mapActions = {
    doLogin: auth.doLogin,
    validateEmail: auth.validateEmail
};
export default connect(mapStateToProps, mapActions)(Login);
